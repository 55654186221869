import React, { useEffect, useState } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import AdminNav from './AdminNav';
import API_BASE_URL from '../components/API';
import { Link } from 'react-router-dom';

function Tables() {
    const [selectedTable, setSelectedTable] = useState('albums');
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData(selectedTable);
    }, [selectedTable]);

    const fetchData = (tableName) => {
        fetch(`${API_BASE_URL}/${tableName}`)
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => console.error(`Error fetching ${tableName}:`, error));
    };

    const handleTableChange = (event) => {
        setSelectedTable(event.target.value);
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}/${selectedTable}/${id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                console.log('Item deleted successfully');
                fetchData(selectedTable); // Refresh the table data after deletion
            } else {
                console.error('Error deleting item');
            }
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    return (
        <div>
            <AdminNav />
            <Container>
                <select onChange={handleTableChange}>
                    <option value="albums">Albums</option>
                    <option value="songs">Songs</option>
                    <option value="singles">Singles</option>
                    <option value="artist">Artist</option>
                    {/* Add options for other tables */}
                </select>
                <Table striped hover size="lg" variant="dark" responsive='sm'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Artist</th>
                            <th>Title</th>
                            <th>Type</th>
                            <th>Hot</th>
                            <th>Buttons</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(item => (
                            <tr key={item.id}>
                                <th>{item.id || item.track}</th>
                                <th>{item.artist || item.name}</th>
                                <th>{item.album_title || item.title}</th>
                                <th>{item.type || item.features}</th>
                                <th>{item.hot}</th>
                                <th>
                                    <Link to={`/update-form`} state={{id: item.id, artist: item.artist, title: item.title, hot: item.hot}}>
                                        <Button variant="success">EDIT</Button> 
                                    </Link>
                                    <i className='m-3'></i>
                                    <Button variant="danger" onClick={() => handleDelete(item.id)}>DELETE</Button>
                                </th>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </div>
    );
}

export default Tables;
