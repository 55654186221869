import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

const About = () => {
    return (
        <div>
            
            <Navigation />
            
        <Container className="mt-5">
          
      <Row>
        <Col>
          <h1>About Kwacha Music</h1>
          <p>

                Welcome to Kwacha Music, your ultimate destination for streaming and downloading the latest
                and greatest music from talented artists across Zambia and beyond. We are dedicated to providing
                a platform where music lovers can discover, enjoy, and support their favorite artists while offering
                artists a gateway to showcase their creativity to a global audience.
              </p>
              <p>
                At Kwacha Music, we understand the importance of accessibility and affordability, which is why we offer
                competitive pricing for uploading music. For just K2500, artists can upload a single song, while albums
                can be uploaded for K6000. This ensures that artists of all levels have the opportunity to share their
                music with the world without breaking the bank.
              </p>
            <p>
                Our platform is designed to be user-friendly, making it easy for music enthusiasts to navigate, discover new music,
                and create personalized playlists. Whether you're into Afrobeat, hip-hop, gospel, or any other genre,
                you'll find a diverse range of music to suit your taste on Kwacha Music.
              </p>
              <p>
                We believe in supporting local talent and fostering a vibrant music community. That's why we encourage artists to

                connect with their fans, promote their music, and build a loyal following through our platform.
                With Kwacha Music, the possibilities are endless for both artists and music lovers alike.
              </p>
              <p>
                For inquiries or assistance, feel free to reach out to us at 0993635965 or 0883673755. Join us on
                Kwacha Music and embark on a musical journey like never before.
          </p>
         
          {/* Add more information as needed */}
        </Col>
      </Row>
        </Container>
        
        <Footer/>
     </div>
  );
};

export default About;
