import React, { useState, useCallback } from 'react';
import API_BASE_URL from '../components/API';
import AdminNav from './AdminNav';
import { Form, Button, Container } from 'react-bootstrap';

function FormSingles() {
  const [artist, setArtist] = useState('');
  const [title, setTitle] = useState('');
  const [hot, setHot] = useState('');
  const [image, setImageFile] = useState(null);
  const [audio, setAudioFile] = useState(null);

  const onDropImage = useCallback((acceptedFiles) => {
    setImageFile(acceptedFiles[0]);
  }, []);

  const onDropAudio = useCallback((acceptedFiles) => {
    setAudioFile(acceptedFiles[0]);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('artist', artist);
    formData.append('title', title);
    formData.append('hot', hot);

    if (image) {
      formData.append('image', image);
    }

    if (audio) {
      formData.append('audio', audio);
    }

    try {
      const response = await fetch(`${API_BASE_URL}/singles`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        console.log('Files uploaded successfully');
        // Clear the form fields after 1 second
        setTimeout(() => {
          setArtist('');
          setTitle('');
          setHot('');
          setImageFile(null);
          setAudioFile(null);
        }, 1000);
      } else {
        console.error('Error uploading files');
        // Handle error as needed
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      // Handle error as needed
    }
  };

  return (
    <div>
      <AdminNav />
      <Container>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="artist">
            <Form.Control type="text" value={artist} placeholder='Artist' className='mb-3' onChange={(e) => setArtist(e.target.value)} />
          </Form.Group>
          <Form.Group controlId="title">
            <Form.Control type="text" value={title} placeholder='Title' className='mb-3' onChange={(e) => setTitle(e.target.value)} />
          </Form.Group>
          <Form.Group controlId="hot">
            <Form.Control type="text" value={hot} placeholder='Hot' className='mb-3' onChange={(e) => setHot(e.target.value)} />
          </Form.Group>
          <Form.Group controlId="image">
            <Form.Label>Image:</Form.Label>
            {image ? (
              <p>{image.name}</p>
            ) : (
              <Form.Control type="file" accept='image/*' onChange={(e) => onDropImage(e.target.files)} />
            )}
          </Form.Group>
          <Form.Group controlId="audio">
            <Form.Label>Audio:</Form.Label>
            {audio ? (
              <p>{audio.name}</p>
            ) : (
              <Form.Control type="file" accept='audio/*' onChange={(e) => onDropAudio(e.target.files)} />
            )}
          </Form.Group>
          <Button variant="success" type="submit">
            Upload
          </Button>
        </Form>
      </Container>
    </div>
  );
}

export default FormSingles;
