import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { Spinner } from 'react-bootstrap';
import API_BASE_URL from '../components/API';
import PRIMARY_URL from '../components/Get_API'


const Data = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [style] = useState({
    color: 'white',
    textDecoration: 'none',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/singles`);
          const result = await response.json();
          console.log(result)
        setData(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // The empty dependency array ensures that the effect runs once when the component mounts

  if (loading) {
    return <div className='spinner'> <Spinner animation='border' variant='warning' /></div>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className='body'>
        <Navigation />
        {/* Singles section */}
        <section className="container">
          <div>
          {loading && <div className='spinner'><Spinner animation='border' variant='warning'/></div>}
            {error && <p>Error: {error.message}</p>}
            {data && (
              <div  className="row">
                {data.map((item) => (
                  <div key={item.id} className="col-md-3 col-sm-6 size">
                    {/* {console.log(location.state)} */}
                    <Link to={`/listen`} state={{image: item.image, audio: item.audio, id: item.id, title: item.title,artist: item.artist}} style={style}>
                    <img src={`${PRIMARY_URL}/${item.image}`} alt={item.title} />
                      <div className="description"> {item.artist} -  {item.title} </div>
                      </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>
        {/* Footer */}
        <Footer />
      </div>
    );
  
};

export default Data;
