import React, { useState, useRef, useEffect } from 'react';
import { FaPause, FaPlay, FaDownload } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import Navigation from './Navigation';
import Footer from './Footer';
import PRIMARY_URL from './Get_API';

const AudioPlayer = () => {
  const location = useLocation();
  const { image, audio, artist, title } = location.state;
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioPlayer = useRef(null);
  const progressBar = useRef(null);
  const [isSeeking, setIsSeeking] = useState(false);

  const togglePlay = () => {
    if (isPlaying) {
      audioPlayer.current.pause();
    } else {
      audioPlayer.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const updateTime = () => {
    setCurrentTime(audioPlayer.current.currentTime);
  };

  const updateProgress = () => {
    if (audioPlayer.current && !isSeeking) {
      const progress = (audioPlayer.current.currentTime / duration) * 100;
      progressBar.current.value = isNaN(progress) ? 0 : progress;
      setCurrentTime(audioPlayer.current.currentTime);
    }
  };

  const handleProgressChange = () => {
    const newTime = (progressBar.current.value * duration) / 100;
    audioPlayer.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleSeeking = (value) => {
    setIsSeeking(true);
    const newTime = (value * duration) / 100;
    setCurrentTime(newTime);
  };

  const handleSeekEnd = (value) => {
    const newTime = (value * duration) / 100;
    audioPlayer.current.currentTime = newTime;
    setCurrentTime(newTime);
    setIsSeeking(false);
  };

const handleDownload = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = `${PRIMARY_URL}/${audio}`;
    downloadLink.setAttribute('download', `${title}.mp3`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};


  const handleCleanup = () => {
    if (audioPlayer.current) {
      audioPlayer.current.removeEventListener('timeupdate', updateProgress);
    }
  };

  useEffect(() => {
    // Load saved playback state from localStorage
    const savedIsPlaying = localStorage.getItem('isPlaying') === 'true';
    setIsPlaying(savedIsPlaying);

    // Load saved currentTime from localStorage
    const savedCurrentTime = parseFloat(localStorage.getItem('currentTime')) || 0;
    setCurrentTime(savedCurrentTime);

    audioPlayer.current.addEventListener('timeupdate', updateProgress);
    audioPlayer.current.addEventListener('loadedmetadata', () => {
      setDuration(audioPlayer.current.duration || 0);
    });
    audioPlayer.current.addEventListener('ended', () => {
      setIsPlaying(false);
      setCurrentTime(0);
      progressBar.current.value = 0;
    });

    return handleCleanup;
  }, [duration]);

  useEffect(() => {
    // Save playback state to localStorage
    localStorage.setItem('isPlaying', isPlaying);

    // Save currentTime to localStorage
    localStorage.setItem('currentTime', currentTime.toString());
  }, [isPlaying, currentTime]);

  useEffect(() => {
    // Cleanup on component unmount
    return handleCleanup;
  }, []);

  const formatTime = (time) => {
    if (!isFinite(time)) return '00:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  return (
   
    <div>
      <Navigation />
      
      <section className='container'>
        <div className='p-3 audio_image'>
          <img src={`${PRIMARY_URL}/${image}`} alt='' />
          <div className='title'>{title}</div>
          <div className='artist'>{artist}</div>
        </div>
      </section>

      <audio ref={audioPlayer} src={`${PRIMARY_URL}/${audio}`} onTimeUpdate={updateTime} />
      <div>
        <progress
          ref={progressBar}
          className='progress-bar'
          max='100'
          value={isNaN((currentTime / duration) * 100) ? 0 : (currentTime / duration) * 100}
          onChange={handleProgressChange}
          onMouseDown={(e) => handleSeeking(e.target.value)}
          onMouseUp={(e) => handleSeekEnd(e.target.value)}
          onTouchStart={(e) => handleSeeking(e.target.value)}
          onTouchEnd={(e) => handleSeekEnd(e.target.value)}
        ></progress>
      </div>
      <div className='pp_btn'>
        <button onClick={togglePlay} className='btn btn-light btn-round'>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>

        <span className='audio_time'>{formatTime(currentTime)}</span> /{' '}
        <span className='audio_time'>{formatTime(duration)}</span>

        <button onClick={handleDownload} className='btn btn-light btn-round'>
          <FaDownload />
        </button>
      </div>
      <Footer/>
    </div>
  );
};

export default AudioPlayer;
