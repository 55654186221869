import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';



function AdminNav() {
  return (
    <>
      {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} className=" bg-dark text-light mb-3 ">
          <Container fluid>
            <Navbar.Brand href="#" className='text-light'><img src='./logo.png' className='logo' alt='Kwachamusic'/></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className='bg-light' />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className='text-light bg-dark'
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className='text-light'>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3" >
                  <Link to="/admin" className=' nav-link text-light'>Singles</Link>
                  <Link to="/upload-albums" className=' nav-link text-light'>Albums</Link>
                  <Link to="/upload-albums-details" className=' nav-link text-light'>Details</Link>
                  <Link to="/upload-artist" className=' nav-link text-light'>Artist</Link>
                  <Link to="/table" className=' nav-link text-light'>Table</Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}

    
    </>
  );
}

export default AdminNav;