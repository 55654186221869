import React, { useState, useEffect } from 'react';
import AdminNav from './AdminNav';
import { Container, Form } from 'react-bootstrap';
import API_BASE_URL from '../components/API';

function FormDetails() {
  const [formData, setFormData] = useState({
    artist: '',
    album_title: '',
    songTitle: '',
    features: '',
    track: 0,
    audio: null,
    audioFileName: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file' && files.length > 0) {
      // Extract the file name from the file input
      const fileName = files[0].name;

      setFormData((prevData) => ({
        ...prevData,
        [name]: fileName, // Store the file name in the state
        audio: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('artist', formData.artist);
    formDataToSend.append('album_title', formData.album_title);
    formDataToSend.append('title', formData.songTitle);
    formDataToSend.append('features', formData.features);
    formDataToSend.append('track', formData.track);
    formDataToSend.append('audio', formData.audio);

    try {
      const response = await fetch(`${API_BASE_URL}/songs`, {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        console.log('Data successfully submitted');

        // Reset the form fields after successful submission
        setFormSubmitted(true);
      } else {
        console.error('Failed to submit data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    let timer;

    if (formSubmitted) {
      // Set a timer to clear the form after 2000 milliseconds (2 seconds)
      timer = setTimeout(() => {
        setFormData({
          artist: '',
          album_title: '',
          songTitle: '',
          features: '',
          track: 0,
          audio: null,
          audioFileName: '',
        });
        setFormSubmitted(false);
      }, 2000);
    }

    return () => clearTimeout(timer); // Clear the timer if the component unmounts

  }, [formSubmitted]);

  return (
    <div>
      <AdminNav />
      <Container>
        {formSubmitted ? (
          <p>Data successfully submitted! Form will be cleared in 2 seconds.</p>
        ) : (
          <Form className='mb-3' onSubmit={handleSubmit}>
            <Form.Control
              type='text'
              placeholder='Artist'
              className='mb-3'
              name='artist'
              value={formData.artist}
              onChange={handleChange}
            />
            <Form.Control
              type='text'
              placeholder='Album Title'
              className='mb-3'
              name='album_title'
              value={formData.albumTitle}
              onChange={handleChange}
            />
            <Form.Control
              type='text'
              placeholder='Song Title'
              className='mb-3'
              name='songTitle'
              value={formData.songTitle}
              onChange={handleChange}
            />
            <Form.Control
              type='text'
              placeholder='Features'
              className='mb-3'
              name='features'
              value={formData.features}
              onChange={handleChange}
            />
            <Form.Control
              type='number'
              placeholder='Track'
              className='mb-3'
              name='track'
              value={formData.track}
              onChange={handleChange} />
                          
            <Form.Control type='file' className='mb-3' name='audio' accept='audio/*' onChange={handleChange}/>
            <button type='submit' className='btn btn-success'>
              Submit
            </button>
          </Form>
        )}
      </Container>
    </div>
  );
}

export default FormDetails;
