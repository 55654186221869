import React, { useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import AdminNav from './AdminNav';
import API_BASE_URL from '../components/API';

function UpdateForm() {
    const location = useLocation();
    const { id, artist: initialArtist, title: initialTitle, hot: initialHot } = location.state;
    const [artist, setArtist] = useState(initialArtist);
    const [title, setTitle] = useState(initialTitle);
    const [hot, setHot] = useState(initialHot);
    const [image, setImage] = useState(null);
    const [audio, setAudio] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('artist', artist);
        formData.append('title', title);
        formData.append('hot', hot);
        if (image) {
            formData.append('image', image);
        }
        if (audio) {
            formData.append('audio', audio);
        }
        try {
            const response = await fetch(`${API_BASE_URL}/singles/${id}`, {
                method: 'PUT',
                body: formData,
            });
            if (response.ok) {
                console.log('Item updated successfully');
                // Handle success as needed
            } else {
                console.error('Error updating item');
                // Handle error as needed
            }
        } catch (error) {
            console.error('Error updating item:', error);
            // Handle error as needed
        }
    };

    return (
        <div>
            <AdminNav />
            <Container>
                <Form onSubmit={handleSubmit}>
                    <Form.Control
                        type='text'
                        value={artist}
                        className='mb-3'
                        onChange={(e) => setArtist(e.target.value)}
                    />
                    <Form.Control
                        type='text'
                        value={title}
                        className='mb-3'
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <Form.Control
                        type='text'
                        value={hot}
                        className='mb-3'
                        onChange={(e) => setHot(e.target.value)}
                    />
                    <Form.Group controlId="image">
                        <Form.Label>Image:</Form.Label>
                        <Form.Control
                            type="file"
                            accept='image/*'
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                    </Form.Group>
                    <Form.Group controlId="audio">
                        <Form.Label>Audio:</Form.Label>
                        <Form.Control
                            type="file"
                            accept='audio/*'
                            onChange={(e) => setAudio(e.target.files[0])}
                        />
                    </Form.Group>
                    <Button variant='success' type='submit'>
                        Update
                    </Button>
                </Form>
            </Container>
        </div>
    );
}

export default UpdateForm;
