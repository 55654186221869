import React, { useState, useEffect } from 'react';
import Navigation from '../components/Navigation';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import API_BASE_URL from '../components/API';
import PRIMARY_URL from '../components/Get_API';


function App() {
  const [albums, setFeaturedSongs] = useState([]);
  const [artists, setCategories] = useState([]);
  const [style] = useState({
    color: 'white',
    textDecoration: 'none',
  });



  useEffect(() => {
    // Fetch data for featured songs
    fetch(`${API_BASE_URL}/singles`)
      .then(response => response.json())
      .then(data => setFeaturedSongs(data.slice(0, 8)))
      .catch(error => console.error('Error fetching featured songs:', error));

    // Fetch data for categories
    fetch(`${API_BASE_URL}/artist`)
      .then(response => response.json())
      .then(data => setCategories(data.slice(0, 10)))
      .catch(error => console.error('Error fetching categories:', error));
    
    
  }, []);

  

  return (
    <div>

      <Navigation />
      
      {/* Featured Songs Section */}
      <section className='container'>
        <div className='heading'>Featured Songs</div>
        <div className='row'>
          {albums.map(item => (
            <div key={item.id} className='col-md-3 col-sm-6 size'>
             <Link to={`/listen`} state={{image: item.image, audio: item.audio, id: item.id, title: item.title,artist: item.artist}} style={style}>
                    <img src={`${PRIMARY_URL}/${item.image}`} alt={item.title} />
                      <div className="description"> {item.artist} -  {item.title} </div>
                      </Link>
            </div>
          ))}
        </div>
      </section>

      {/* Categories Section */}
      <section>
        <div className='heading'>Artist</div>
        <div className='container'>
          <div className='wrapper'>
            {artists.map(artist => (
              <div key={artist.id} className='img item'>
                <Link to={`/artist-songs`} state={{ artist: artist.name}} style={style}>
                <img src={`${PRIMARY_URL}/${artist.image}`} alt={artist.title} />
                  <div className='cart-description'>{`${artist.name} `}</div>
                  </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
}

export default App;
