import { useState } from 'react';
import { Button, Container, Form, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Search from './Search';


function Navigation() {
  
  return (
    <>
      {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} className=" bg-dark text-light mb-3 ">
          <Container fluid>
            <Navbar.Brand><Link to='/home' ><img src='./logo.png' className='logo' alt='Kwachamusic'/></Link></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className=' bg-light justify-content-left' />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton className='text-light bg-dark' >
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className='text-white'>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className='bg-dark'>
                <Nav className="justify-content-end flex-grow-1 pe-3" >
                  <Link to="/home" className='nav-link text-light'>Home</Link>
                  <Link to="/singles" className='nav-link text-light'>Singles</Link>
                  <Link to="/albums" className='nav-link text-light'>Albums</Link>
                  <Link to="/artist" className='nav-link text-light'>Artist</Link>
                  <Link to="/about" className='nav-link text-light'>About</Link>
                
                </Nav>
               
              <Search/>
      
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Navigation;