import { Navigate, createBrowserRouter } from 'react-router-dom'
import Home from './view/Home'
import Singles from './view/Singles'
import Albums from './view/Albums'
import DefaultPage from './components/DefaultPage'
import AudioPlayer from './components/AudioPlayer'
import Songs from './components/Songs'
import Stream from './components/Stream'
import Artist from './view/Artist'
import ArtistSongs from './components/ArtistSongs'
import FormSingles from './admin/FormSingles'
import FormAlbums from './admin/FormAlbums'
import FormDetails from './admin/FormDetails'
import FormArtist from './admin/FormArtist'
import About from './view/About'
import Tables from './admin/Tables'
import UpdateForm from './admin/UpdateForm'
import Search from './components/Search'



const router = createBrowserRouter([

    {
        path: '/',
        element: <DefaultPage />,
        children: [
            {
                path: '/',
                element: <Navigate to='/home' />
            }
        ]
    },
    {
        path: '/home',
        element: <Home />
    },
    {
        path: '/singles',
        element: <Singles />
    },
    {
        path: '/albums',
        element: <Albums />
    },
    {
        path: '/listen',
        element: <AudioPlayer />
    },
    {
        path: '/songs',
        element: <Songs />
    },
    {
        path: '/stream',
        element: <Stream />
    },
    {
        path: '/artist',
        element: <Artist />
    },
    {
        path: '/about',
        element: <About/>
    },
    {
        path: '/artist-songs',
        element: <ArtistSongs />
    },
    {
        path: '/admin',
        element: <FormSingles />
    },
    {
        path: '/upload-albums',
        element: <FormAlbums/>
    },
    {
        path: '/upload-albums-details',
        element: <FormDetails/>
    },
    {
        path: '/upload-artist',
        element: <FormArtist/>
    },
    {
        path: '/table',
        element: <Tables/>
    },
    {
        path: '/update-form',
        element: <UpdateForm/>
    },
    {
        path: '/search',
        element: <Search/>
    }


])

export default router;