import React, { useState } from 'react';
import {Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PRIMARY_URL from './Get_API';

function Search() {
  const [query, setQuery] = useState('');
  const [data, setData] = useState([]);
  const [style] = useState({
    color: 'white',
    textDecoration: 'none',
  });

  async function search(query, apiEndpoints) {
    console.log(query);
    const results = [];

    for (const endpoint of apiEndpoints) {
      const response = await fetch(`http://127.0.0.1:8000/api/${endpoint}/${query}`);
      const result = await response.json();
      results.push(...result);
    }

    setData(results);
  }

  return (
    <div>
      <Form className="d-flex">
        <Form.Control
          type="text"
          value={query}
          onChange={(e) => {
            const query = e.target.value;
            setQuery(query); // Update the state
            search(query, ['search', 'songSearch']); // Call the search function with the complete query and array of endpoints
          }}
          placeholder="Enter search query"
        />
      </Form>

      <section className="container">
        <div>
          
          {data && (
            <div className="row">
              {data.map((item) => (
                <div key={item.id} className="col-md-3 col-sm-6 size">
                  {/* Conditional rendering of links based on endpoint */}
                  {item.endpoint = 'search' ? (
                    <Link
                      to={`/songs`}
                      state={{
                        id: item.id,
                        image: item.image,
                        type: item.type,
                        album_title: item.album_title,
                        artist: item.artist,
                      }}
                    >
                      <img src={`${PRIMARY_URL}/${item.image}`} alt={item.album_title} />
                      <div className="description2"> {item.album_title} </div>
                      <div className="album-desc">{item.type}</div>
                    </Link>
                  ) : (
                    <Link to={`/listen`} state={{image: item.image, audio: item.audio, id: item.id, title: item.title,artist: item.artist}} style={style}>
                      <img src={`${PRIMARY_URL}/${item.image}`} alt={item.title} />
                      <div className="description"> {item.artist} -  {item.title} </div>
                    </Link>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default Search;
