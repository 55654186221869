import React, { useState, useEffect } from 'react';
import AdminNav from './AdminNav';
import { Container, Form } from 'react-bootstrap';
import API_BASE_URL from '../components/API';

function FormAlbums() {
  const [formData, setFormData] = useState({
    artist: '',
    album_title: '',
    type: '',
    image: null,
    hot: ''
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      // Extract the filename with extension
      const fileName = files[0].name;
      setFormData((prevData) => ({
        ...prevData,
        [name]: fileName,
        image: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('artist', formData.artist);
    formDataToSend.append('album_title', formData.album_title);
    formDataToSend.append('type', formData.type);
    formDataToSend.append('image', formData.image);
    formDataToSend.append('hot', formData.hot);

    try {
      const response = await fetch(`${API_BASE_URL}/albums`, {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        console.log('Data successfully submitted');
        setFormSubmitted(true); // Set state to indicate form submission
      } else {
        console.error('Failed to submit data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (formSubmitted) {
      // Reset the form fields after a short delay to allow re-render
      const resetForm = setTimeout(() => {
        setFormData({
          artist: '',
          album_title: '',
          type: '',
          image: null,
          hot: ''
        });
        setFormSubmitted(false);
      }, 500); // Adjust the delay as needed

      return () => clearTimeout(resetForm); // Clear the timeout if the component unmounts
    }
  }, [formSubmitted]);

  return (
    <div>
      <AdminNav />
      <Container>
        {formSubmitted ? (
          <p>Data successfully submitted! Form has been hidden.</p>
        ) : (
          <Form className='mb-3' onSubmit={handleSubmit}>
            <Form.Control
              type='text'
              placeholder='Artist'
              className='mb-3'
              name='artist'
              value={formData.artist}
              onChange={handleChange}
            />
            <Form.Control
              type='text'
              placeholder='Album Title'
              className='mb-3'
              name='album_title'
              value={formData.album_title}
              onChange={handleChange}
            />
            <Form.Control
              type='text'
              placeholder='Type'
              className='mb-3'
              name='type'
              value={formData.type}
              onChange={handleChange}
            />
            <Form.Control
              type='file'
              className='mb-3'
              name='image'
              accept='image/*'
              onChange={handleChange}
              />
              <Form.Control
              type='text'
              placeholder='Hot'
              className='mb-3'
              name='hot'
              value={formData.hot}
              onChange={handleChange}
            />
            <button type='submit' className='btn btn-success'>
              Submit
            </button>
          </Form>
        )}
      </Container>
    </div>
  );
}

export default FormAlbums;
