import React, { useState, useEffect } from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import API_BASE_URL from '../components/API';
import PRIMARY_URL from '../components/Get_API';

const Artist = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [style] = useState({
    color: 'white',
    textDecoration: 'none',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/artist`);
          const result = await response.json();
          console.log(result)
        setData(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // The empty dependency array ensures that the effect runs once when the component mounts

  if (loading) {
    return <p className='spinner'><Spinner animation='border' variant='warning'/></p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div >
        <Navigation />
        {/* Singles section */}
        <section className="container">
          <div>
            {loading && <p className='spinner'><Spinner animation='border' variant='warning'/></p>}
            {error && <p>Error: {error.message}</p>}
            {data && (
              <div  className="row">
                {data.map((item) => (
                  <div key={item.id} className="col-md-3 col-sm-6 artist ">
                    <Link to={`/artist-songs`} state={{ artist: item.name}} style={style}>
                    <img src={`${PRIMARY_URL}/${item.image}`} alt={item.name}  className='rounded-circle'/>
                        <div className="artist_desc text-center"> {item.name} </div>
                      </Link>
                     
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>
        {/* Footer */}
        <Footer />
      </div>
    );
  
};

export default Artist;
