import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/style.css';

class Footer extends Component {
  render() {
    return (
      <div>
        {/* Footer */}
        <section>
          <footer className="bg-dark text-light py-4">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <p className='mb-0'>&copy; 2023 - 2024</p>
                </div>
                <div className="col-md-6 text-md-end">
                  <p className='mb-0'>www.kwachamusic.com</p>
                </div>

                
              </div>
              {/* Uncomment the following code for the list of links */}
              <ul className='list-inline mt-4'>
                <li className='list-inline-item'><Link to="/" className='text-light text-decoration-none'>Home</Link></li>
                <li className='list-inline-item'><Link to="/singles" className='text-light text-decoration-none'>Singles</Link></li>
                <li className='list-inline-item'><Link to="/albums" className='text-light text-decoration-none'>Albums</Link></li>
                <li className='list-inline-item'><Link to="/artist" className='text-light text-decoration-none'>Artist</Link></li>
                <li className='list-inline-item'><Link to="/about" className='text-light text-decoration-none'>About</Link></li>
                 <li className='list-inline-item'><Link to="/admin" className='text-light text-decoration-none'>Ad</Link></li>
              </ul>
            </div>
          </footer>
        </section>
      </div>
    );
  }
}

export default Footer;
