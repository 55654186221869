import React, { useState, useEffect } from 'react';
import AdminNav from './AdminNav';
import { Container, Form } from 'react-bootstrap';
import API_BASE_URL from '../components/API';

function FormArtist() {
  const [formData, setFormData] = useState({
    name: '',
    bio: '',
    image: null,
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file' && files.length > 0) {
      // Extract the file name from the file input
      const fileName = files[0].name;

      setFormData((prevData) => ({
        ...prevData,
        [name]: fileName, // Store the file name in the state
        image: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('bio', formData.bio);
    formDataToSend.append('image', formData.image);

    try {
      const response = await fetch(`${API_BASE_URL}/artist`, {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        console.log('Data successfully submitted');

        // Reset the form fields after successful submission
        setFormSubmitted(true);
      } else {
        console.error('Failed to submit data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    let timer;

    if (formSubmitted) {
      // Set a timer to clear the form after 2000 milliseconds (2 seconds)
      timer = setTimeout(() => {
        setFormData({
          name: '',
          bio: '',
          image: null,
        });
        setFormSubmitted(false);
      }, 500);
    }

    return () => clearTimeout(timer); // Clear the timer if the component unmounts

  }, [formSubmitted]);

  return (
    <div>
      <AdminNav />
      <Container>
        {formSubmitted ? (
          <p>Data successfully submitted! Form will be cleared in 2 seconds.</p>
        ) : (
          <Form className='mb-3' onSubmit={handleSubmit}>
            <Form.Control
              type='text'
              placeholder='Artist'
              className='mb-3'
              name='name'
              value={formData.artist}
              onChange={handleChange}
            />
            <Form.Control
              type='text'
              placeholder='Bio'
              className='mb-3'
              name='bio'
              value={formData.bio}
              onChange={handleChange}
            />
            <Form.Control
              type='file'
              className='mb-3'
              name='image'
              accept='image/*'
              onChange={handleChange}
            />
            <button type='submit' className='btn btn-success'>
              Submit
            </button>
          </Form>
        )}
      </Container>
    </div>
  );
}

export default FormArtist;
