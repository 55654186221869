import React, { useState, useEffect } from 'react';
import Navigation from '../components/Navigation';
import { Link, useLocation } from 'react-router-dom';
import API_BASE_URL from './API';
import PRIMARY_URL from './Get_API';

function ArtistSongs() { 
    const location = useLocation();
    const { artist } = location.state;
  const [albums, setAlbums] = useState([]);
  const [singles, setSingles] = useState([]);

  useEffect(() => {
    // Fetch data for albums
    fetch(`${API_BASE_URL}/albums`)
      .then(response => response.json())
      .then(data => setAlbums(data))
      .catch(error => console.error('Error fetching featured songs:', error));

    // Fetch data for singles
    fetch(`${API_BASE_URL}/singles`)
      .then(response => response.json())
      .then(data => setSingles(data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

    const filterAlbum = albums.filter(album => album.artist === artist);
    const filterSingles = singles.filter(single => single.artist === artist);
  return (
    <div>

      <Navigation />
      
      {/* Albums for artist */}
      <section className='container'>
          <div  className="row">
                {filterAlbum.map((item) => (
                  <div key={item.id} className="col-md-3 col-sm-6 size">
                    <Link to={ `/songs` } state={{id: item.id, image: item.image, type: item.type, album_title: item.album_title, artist: item.artist}} >
                    <img src={`${PRIMARY_URL}/${item.image}`} alt={item.album_title} />
                        <div className="description2"> {item.album_title} </div>
                      <div className='album-desc'>{item.type}</div>
                      </Link>
                  </div>
                ))}
              </div>
      </section>

      {/* Singles artist */}
      
         <section className="container">
              <div>
                <div  className="row">
                {filterSingles.map((item) => (
                  <div key={item.id} className="col-md-3 col-sm-6 size">
                    {/* {console.log(location.state)} */}
                    <Link to={`/listen`} state={{image: item.image, audio: item.audio, id: item.id, title: item.title,artist: item.artist}}>
                    <img src={`${PRIMARY_URL}/${item.image}`} alt={item.title} />
                      <div className="description"> {item.artist} -  {item.title} </div>
                      </Link>
                  </div>
                ))}
              </div>
          </div>
        </section>
    </div>
  );
}

export default ArtistSongs;
