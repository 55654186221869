import React, { useEffect, useState } from 'react'
import Navigation from './Navigation'
import Footer from './Footer'
import { Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import API_BASE_URL from './API'
import PRIMARY_URL from './Get_API'

const Songs = () => {
    const location = useLocation();
    const { image, type,album_title, artist } = location.state;
    const [songs, setSongs] = useState([]);
      const [style] = useState({
    color: 'white',
    textDecoration: 'none',
      });
    
    const imageUrl =  `${PRIMARY_URL}/${image}`;
  const title = {album_title};
  const description = 'Check out the latest track ';
  const url = `www.kwachamusic.com/stream/${album_title}`;

    

    useEffect(() => {
        fetch(`${API_BASE_URL}/songs`)
       .then(response => response.json())
        .then(data => setSongs(data))
        .catch(error => console.error('Error fetching categories:', error));
    }, []); 
    
    const filterSongs = songs.filter(song => song.album_title === album_title);

  return (
      <div>
          
          <Helmet>
        {/* Open Graph (OG) metadata for Facebook, WhatsApp, etc. */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={url} />

        {/* Twitter Card metadata for Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>
          <Navigation />
          
          <section className='songs row'>
              {/* IMAGE, ALBUM OR EP TITLE, ARTIST NAME  */}
              
              <div className='col-md-6'>
                  {/* <img src={`./images/${image}`} alt='' /> */}
                  <img src={`${PRIMARY_URL}/${image}`} alt='' />

              </div>

              <div className=' col-md-2 song-column'> 
                    <div className='song-album'>
                        <p>{type}</p>
                        <div className='song-artist2'>{album_title}</div>
                    </div>

                    <div className='song-album'>
                        <p>Artist</p>
                        <div className='song-artist2'>{artist}</div>
                    </div>
              </div>
             
{/* TABLE SECTION */}
              <table>
                  <tbody>
                      <tr>
                      <td>
                          {filterSongs.map(song => ( 
                              <div key={song.id} className='song-title'>
                                  <Link to={`/stream`} state={{ id: song.id, title: song.title, features: song.features, audio: song.audio, album_title: song.album_title}} style={style}>
                                <p>{song.track}. {song.title}</p>
                                      <div className='song-artist'>{song.features}</div>
                                      </Link>
                                  </div>
                          ))}
                              </td>
                          </tr>      
                            
                  </tbody>
                  
                  
              </table>
          </section>
        
          <Footer/>
    </div>
  )
}

export default Songs